import { Directive, Self, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[proTrimOnBlur]',
})
export class TrimOnBlurDirective {
  @Input('proTrimOnBlur') mode: 'trim' | 'noWhiteSpace' = 'trim';

  @HostListener('focusout') onBlur() {
    if (this?.controlElem?.control) {
      if (this.mode === 'noWhiteSpace') {
        this.controlElem.control.setValue(
          this.controlElem.control.value?.toString().trim().split(' ').join('')
        );
      } else {
        this.controlElem.control.setValue(this.controlElem.control.value?.toString().trim());
      }
    }
  }

  @HostListener('keydown.space', ['$event']) onKeyDownSpace(event: Event) {
    if (this.mode === 'noWhiteSpace') {
      event.preventDefault();
    }
  }

  constructor(@Self() private controlElem: NgControl) {}
}
