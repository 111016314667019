import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlErrorDirective,
  ControlErrorMessageDirective,
  ControlErrorComponent,
  FormErrorDirective,
  FormErrorComponent,
} from './form-error';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [
    ControlErrorDirective,
    ControlErrorMessageDirective,
    ControlErrorComponent,
    FormErrorComponent,
    FormErrorDirective,
  ],
  exports: [
    ControlErrorDirective,
    ControlErrorMessageDirective,
    ControlErrorComponent,
    FormErrorComponent,
    FormErrorDirective,
  ],
})
export class FormErrorModule {}
