import { Component, NgZone, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ActivatedRoute, NavigationStart, Router, NavigationEnd } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import * as LiveUpdates from '@capacitor/live-updates';
import { first, map } from 'rxjs';
// import OneSignal from 'onesignal-cordova-plugin';
import { App } from '@capacitor/app';
import { AppStoreFacade } from './app-store/app-store-facade/app-store.facade';
import { modalTransition, pageTransition } from './animations';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  template: `<ion-app>
    <pro-global-notice></pro-global-notice>
    <pro-measurement-transfer></pro-measurement-transfer>
    <ion-router-outlet [animation]="animation$ | async"></ion-router-outlet>
  </ion-app>`,
})
export class AppComponent implements OnInit {
  animation$ = this.facade.animation$.pipe(
    map((a) => {
      return a === 'modal' ? modalTransition : pageTransition;
    })
  );

  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private facade: AppStoreFacade
  ) {}

  ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        this.initializeApp();
      }

      if (this.platform.is('android')) {
        // registers a high priority handler for the android back-button
        this.platform.backButton.subscribeWithPriority(666, this.handleAndroidHardwareBackButton);
      }

      // applies the prefered color-sheme
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      this.toggleDarkTheme(prefersDark.matches);
      // listens for changes to the prefers-color-scheme media query
      prefersDark.addEventListener('change', (mediaQuery) => {
        this.toggleDarkTheme(mediaQuery.matches);
      });

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart || event instanceof NavigationEnd) {
          this.applyNewDesign();
        }
      });
      this.applyNewDesign();
    });
  }

  initializeApp() {
    SplashScreen.hide();
    StatusBar.setStyle({ style: Style.Light });

    // enable live updates, depending on app config auto_update property
    this.facade
      .loadAppConfig()
      .pipe(first())
      .subscribe((config) => {
        if (config.auto_update) {
          this.enableLiveUpdates();
        }
      });

    this.platform.keyboardDidShow.subscribe(() => {
      document.body.classList.add('keyboard-open');
    });

    this.platform.keyboardDidHide.subscribe(() => {
      document.body.classList.remove('keyboard-open');
    });

    // OneSignal.setNotificationOpenedHandler((event) => {
    //   this.zone.run(() => {
    //     // must run inside zone when router navigate is invoked on app initialisation
    //     if (
    //       event.notification.additionalData &&
    //       (event.notification.additionalData as Record<string, string>).message_id
    //     ) {
    //       this.router.navigate(['app/message'], {
    //         queryParams: {
    //           id: (event.notification.additionalData as Record<string, string>).message_id,
    //         },
    //       });
    //     } else {
    //       this.router.navigate(['app/overview/inbox']);
    //     }
    //   });
    // });
  }

  handleAndroidHardwareBackButton = (processNextHandler: () => void) => {
    // finds last firstChild in current snapshot
    let snapshot = this.activatedRoute?.snapshot;

    while (snapshot?.firstChild) {
      snapshot = snapshot.firstChild;
    }
    // closes app on back-button click
    if (snapshot?.data?.androidBackButtonCloseApp) {
      App.minimizeApp();
    }
    // executes next registered handler
    if (!snapshot?.data?.androidBackButtonIgnore) {
      processNextHandler();
    }
  };

  toggleDarkTheme(shouldAdd: boolean) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  applyNewDesign() {
    [
      'pro-welcome',
      'pro-registration-preconditions',
      'pro-registration-consents',
      'pro-registration-user-name',
      'pro-registration-user-data',
      'pro-registration-credentials',
      'pro-registration-confirm-email',
    ].map((selector) => {
      // checks if component ist visible
      const componentElement = document.querySelector(selector);
      // adds class to the component element to apply new design
      if (componentElement?.classList?.contains('pro-new-design') === false) {
        componentElement.classList.add('pro-new-design');
      }
    });
  }

  private async enableLiveUpdates() {
    const result = await LiveUpdates.sync();
    // if active path has changed, then a new live update was downloaded
    if (result.activeApplicationPathChanged) {
      // reload the app, the new version will appear afterward
      await LiveUpdates.reload();
    }
  }
}
