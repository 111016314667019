import { Directive, ElementRef, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  // eslint-disable-next-line
  selector: 'ion-input',
})
export class ScrollOnFocusDirective {
  @HostListener('focusin') onFocus() {
    if (this.platform.is('hybrid') === false) {
      return;
    }

    const targetElement =
      this.element?.nativeElement?.closest('ion-item') ||
      this.element?.nativeElement?.closest('ion-row');

    // waits until the keyboard was opened to get the current view-height
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 550);
    }
  }

  constructor(private element: ElementRef, private platform: Platform) {}
}
