import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from './pipe/pipe.module';
import { IconBadgeComponent } from './icon-badge/icon-badge.component';
import {
  ExpansionPanelComponent,
  ExpansionContentComponent,
  ExpansionHeaderComponent,
} from './expansion-panel/expansion-panel.component';
import { ToggleCheckboxComponent } from './toggle-checkbox/toggle-checkbox.component';
import {
  TransferLoadingComponent,
  TransferTextDirective,
} from './transfer-loading/transfer-loading.component';
import { ModalNavOutletComponent } from './modal-nav-outlet/modal-nav-outlet.component';
import { ErrorComponent } from './error/error.component';
import { FormErrorModule } from './form-error/form-error.module';
import { StepIndicatorComponent } from './step-indicator.component';
import { ValuePickerComponent } from './value-picker/value-picker.component';
import { FormInfoDirective, FormInfoComponent } from './form-info/form-info';
import { AddressFormGroupComponent } from './address-form-group/address-form-group.component';
import { PhoneFormGroupComponent } from './phone-form-group/phone-form-group.component';
import { ListActionsComponent } from './list-actions/list-actions.component';
import { ModalButtonsComponent } from './modal-buttons/modal-buttons.component';
import { PasswordFormGroupComponent } from './password-form-group/password-form-group.component';
import {
  ConfirmationAlertComponent,
  ConfirmationAlertDirective,
} from './confirmation-alert/confirmation-alert.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DocumentIconComponent } from './document-icon/document-icon.component';
import { DeviceIconComponent } from './device-icon.component';
import { DeviceResultItemComponent } from './device-result-item/device-result-item.component';
import { DeviceResultLabelComponent } from './device-result-label/device-result-label.component';
import { DeviceImageComponent } from './device-image/device-image.component';
import { DeviceSwitchComponent } from './device-switch/device-switch.component';
import {
  InfoDirective,
  BluetoothInfoComponent,
  BluetoothActivateInfoComponent,
  DeviceHelpComponent,
  DefiInfoComponent,
  MedicationChangeInfoComponent,
  PhysicianNumberInfoComponent,
  AppSubscriptionsInfoComponent,
  AppSubscriptionsInvalidCodeInfoComponent,
  SixLeadEcgInfoComponent,
} from './info';
import { TrafficLightComponent } from './traffic-light/traffic-light.component';
import { TrimOnBlurDirective } from './trim-on-blur/trim-on-blur.directive';
import { IntakesBadgeComponent } from './intakes-badge/intakes-badge.component';
import { ConfirmPasswordComponent } from './confirm-password/confirm-password.component';
import { LinkDirective } from './link/link.directive';
import { BiometricIconComponent } from './biometric-icon/biometric-icon.component';
import {
  DocumentItemComponent,
  DocumentItemContentComponent,
} from './document-item/document-item.component';
import { ImageComponent } from './image/image.component';
import { MonitoringGoalTileComponent } from './monitoring-goal-tile/monitoring-goal-tile.component';
import {
  AndroidLocationInfoComponent,
  AndroidLocationInfoModalComponent,
  AndroidLocationSettingsInfoModalComponent,
} from './android-location-info/android-location-info.component';
import { HorizontalStepperDirective } from './horizontal-stepper.directive';
import {
  ChartComponent,
  BaseChartComponent,
  LineChartComponent,
  IconChartComponent,
} from './chart';
import { DatePickerItemControlComponent } from './date-picker-item-control/date-picker-item-control.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { FormDirective } from './form/form.directive';
import { ScrollNextOnCheckDirective } from './scrollNextOnCheck/scroll-next-on-check.directive';
import {
  MessageCardCompactComponent,
  MessageCardExtendedComponent,
  MessageCardTitleComponent,
} from './message-card/message-card.component';
import { MessageNoMessageImageComponent } from './message-no-message-image/message-no-message-image.component';
import { ScrollOnFocusDirective } from './scroll-on-focus/scroll-on-focus.directive';

@NgModule({
  declarations: [
    IconBadgeComponent,
    TrafficLightComponent,
    IntakesBadgeComponent,
    ExpansionPanelComponent,
    ExpansionContentComponent,
    ExpansionHeaderComponent,
    ToggleCheckboxComponent,
    TransferLoadingComponent,
    TransferTextDirective,
    ModalNavOutletComponent,
    ErrorComponent,
    PhoneFormGroupComponent,
    StepIndicatorComponent,
    ValuePickerComponent,
    FormInfoDirective,
    FormInfoComponent,
    AddressFormGroupComponent,
    ListActionsComponent,
    ModalButtonsComponent,
    PasswordFormGroupComponent,
    ConfirmationAlertComponent,
    ConfirmationAlertDirective,
    PdfViewerComponent,
    DocumentIconComponent,
    DeviceIconComponent,
    DeviceResultItemComponent,
    DeviceResultLabelComponent,
    DeviceImageComponent,
    DeviceSwitchComponent,
    InfoDirective,
    BluetoothInfoComponent,
    BluetoothActivateInfoComponent,
    DeviceHelpComponent,
    DefiInfoComponent,
    MedicationChangeInfoComponent,
    PhysicianNumberInfoComponent,
    AppSubscriptionsInfoComponent,
    AppSubscriptionsInvalidCodeInfoComponent,
    SixLeadEcgInfoComponent,
    TrimOnBlurDirective,
    ConfirmPasswordComponent,
    LinkDirective,
    BiometricIconComponent,
    DocumentItemComponent,
    DocumentItemContentComponent,
    ImageComponent,
    MonitoringGoalTileComponent,
    AndroidLocationInfoComponent,
    AndroidLocationInfoModalComponent,
    AndroidLocationSettingsInfoModalComponent,
    HorizontalStepperDirective,
    ChartComponent,
    LineChartComponent,
    BaseChartComponent,
    IconChartComponent,
    DatePickerItemControlComponent,
    FilePreviewComponent,
    FormDirective,
    ScrollNextOnCheckDirective,
    MessageCardCompactComponent,
    MessageCardExtendedComponent,
    MessageCardTitleComponent,
    MessageNoMessageImageComponent,
    ScrollOnFocusDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    PipeModule,
    FormErrorModule,
    PdfViewerModule,
    RouterModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    IconBadgeComponent,
    TrafficLightComponent,
    IntakesBadgeComponent,
    ExpansionPanelComponent,
    ExpansionContentComponent,
    ExpansionHeaderComponent,
    ToggleCheckboxComponent,
    TransferLoadingComponent,
    TransferTextDirective,
    ModalNavOutletComponent,
    ErrorComponent,
    FormErrorModule,
    PhoneFormGroupComponent,
    StepIndicatorComponent,
    ValuePickerComponent,
    FormInfoDirective,
    FormInfoComponent,
    AddressFormGroupComponent,
    ListActionsComponent,
    ModalButtonsComponent,
    PasswordFormGroupComponent,
    ConfirmationAlertComponent,
    ConfirmationAlertDirective,
    PdfViewerComponent,
    DocumentIconComponent,
    DeviceIconComponent,
    DeviceResultItemComponent,
    DeviceResultLabelComponent,
    DeviceImageComponent,
    DeviceSwitchComponent,
    InfoDirective,
    TrimOnBlurDirective,
    LinkDirective,
    RouterModule,
    PdfViewerModule,
    BiometricIconComponent,
    DocumentItemComponent,
    DocumentItemContentComponent,
    ImageComponent,
    MonitoringGoalTileComponent,
    AndroidLocationInfoComponent,
    AndroidLocationInfoModalComponent,
    AndroidLocationSettingsInfoModalComponent,
    HorizontalStepperDirective,
    ChartComponent,
    LineChartComponent,
    BaseChartComponent,
    IconChartComponent,
    DatePickerItemControlComponent,
    FilePreviewComponent,
    FormDirective,
    ScrollNextOnCheckDirective,
    MessageCardCompactComponent,
    MessageCardExtendedComponent,
    MessageNoMessageImageComponent,
    ScrollOnFocusDirective,
  ],
})
export class SharedModule {}
