import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { IonCheckbox } from '@ionic/angular';

@Directive({
  selector: '[proScrollNextOnCheck]',
})
export class ScrollNextOnCheckDirective implements OnInit {
  @Input('proScrollNextOnCheck') targetCheckbox: IonCheckbox;

  @HostListener('click', ['$event.target']) onClick(clickedElement: HTMLElement) {
    this.scroll(clickedElement);
  }

  content: HTMLIonContentElement;
  targetContainer: HTMLIonRowElement | HTMLIonItemElement;

  constructor() {}

  ngOnInit(): void {
    if (this.targetCheckbox) {
      this.content = this.targetCheckbox['el']?.closest('ion-content');
      this.targetContainer =
        this.targetCheckbox['el']?.closest('ion-item') ||
        this.targetCheckbox['el']?.closest('ion-row');
    }
  }

  scroll(clickedElement: HTMLElement) {
    // find the next checkbox in case no checkbox was clicked directly but a child element was clicked (e.g. a sub-label)
    const sourceCheckbox = clickedElement?.closest('ion-checkbox');

    if (this.content && this.targetContainer && sourceCheckbox) {
      // waits until class-list was updated
      setTimeout(() => {
        // procceed only if checkbox was checked
        if (sourceCheckbox.classList.contains('checkbox-checked')) {
          // scrolls to the target-element with a litte space on the top
          this.content.scrollToPoint(0, this.targetContainer.offsetTop - 12, 450);
        }
      }, 150);
    }
  }
}
