import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pro-step-indicator',
  template: `
    <span
      *ngFor="let step of repeatArray; index as index; last as isLast"
      [ngClass]="{ active: index < current, last: isLast }"
    ></span>
  `,
  styles: [
    `
      :host {
        display: flex;
        margin-right: 6.6%;
        margin-bottom: 19px;
      }
      span {
        display: block;
        flex: 1;
        height: 8px;
        border-radius: 4px;
        background-color: var(--ion-color-medium);
        margin-right: 5px;
      }
      span.active {
        background-color: var(--ion-color-secondary);
      }
      span.last {
        margin: 0;
      }

      :host.pro-new-design {
        display: flex;
        justify-content: space-between;
        margin: 0 0 32px 0;
        padding: 4px 0;
        overflow: hidden;
        border-radius: 8px;

        span {
          position: relative;
          display: block;
          flex: 0 0 16px;
          height: 16px;
          width: 16px;
          border-radius: 50%;
          background-color: var(--ion-color-tertiary);
          z-index: 0;

          &::before {
            content: '';
            display: block;
            position: absolute;
            background-color: var(--ion-color-tertiary);
            top: 6px;
            right: 6px;
            height: 4px;
            width: 100vw;
          }
        }

        span.active {
          background-color: var(--ion-color-primary);
          z-index: 1;

          &::before {
            background-color: var(--ion-color-primary);
          }
        }
      }
    `,
  ],
})
export class StepIndicatorComponent implements OnInit {
  @Input() total = 5;
  @Input() current = 1;
  repeatArray: any[];
  constructor() {}

  ngOnInit() {
    this.repeatArray = Array(this.total);
  }
}
